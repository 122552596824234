<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <el-select
        :autocomplete="field.meta && field.meta.autocomplete ? field.meta.autocomplete : 'off'"
        :class="checkinputRequired(field.dbName) ? 'validate-error' : ''"
        :clearable="field.meta && field.meta.clearable ? field.meta.clearable : ''"
        :collapse-tags="field.meta && field.meta.collapseTags ? field.meta.collapseTags : false"
        :disabled="field.meta && field.meta.disabled ? field.meta.disabled : false"
        :filterable="typeof field.meta.filterable === 'undefined' ? true : field.meta && !!field.meta.filterable"
        :multiple="field.meta && field.meta.multiple ? field.meta.multiple : true"
        :multiple-limit="field.meta && field.meta.multipleLimit ? field.meta.multipleLimit : 0"
        :name="field.dbName"
        :no-data-text="field.meta && field.meta.noDataText ? field.meta.noDataText : 'No data'"
        :no-match-text="field.meta && field.meta.noMatchText ? field.meta.noMatchText : 'No matching data'"
        :placeholder="isRemote ? $t('system.enter_3_or_more_characters') : $t('system.select')"
        :remote="isRemote"
        :remote-method="isRemote ? remoteMethod : () => {}"
        :reserve-keyword="field.meta && field.meta.reserveKeyword ? field.meta.reserveKeyword : false"
        :size="field.meta && field.meta.size ? field.meta.size : ''"
        v-model="value"
    >
      <template slot="empty">
        <div class="el-select-dropdown__empty">
          {{
            showRemoteTip ? $t('system.enter_3_or_more_characters') : loading ? $t('system.loading') : $t('system.no_data')
          }}
        </div>
      </template>
      <el-option
          :key="item.id"
          :label="item.name"
          :value="item.id"
          v-for="item in dictionaries && Array.isArray(dictionaries[dictionaryName]) ? dictionaries[dictionaryName] : []"
      >
      </el-option>
    </el-select>
    <small
        class="help-block"
        v-if="backend_errors[field.dbName] && !checkinputRequired(field.dbName)"
    >
      <span v-html="validationTranslate(backend_errors[field.dbName][0])"></span>
    </small>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  mixins: [abstractForm],
  name: 'CreateOrUpdate',
  components: {BaseFormItem},
  beforeMount() {
    if (!this.value) {
      this.value = [];
    }
  },
  data() {
    return {
      searchPhrase: '',
      loading: false,
    };
  },
  computed: {
    isRemote() {
      return !!(this.field && this.field.dictionary && this.field.dictionary.is_remote);
    },
    showRemoteTip() {
      return !!(this.isRemote && this.searchPhrase.length < 3 && !this.value);
    },
  },
  methods: {
    async remoteMethod(val) {
      this.searchPhrase = val;
      if (val.length > 2) {
        this.loading = true;
        const {data} = await this.fetchDictionary(
            `aberit_module_generator/get_dictionary_data?dictionary_name=${this.dictionaryName}`,
            {search: val},
        );
        if (data) {
          this.dictionaries[this.dictionaryName] = data;
        }
        this.loading = false;
      } else {
        this.dictionaries[this.dictionaryName] = [];
      }
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>

</style>
